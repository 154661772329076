import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { width, space as styledSpace } from 'styled-system'
import { space, fontSizes, fontWeights, radius } from 'theme'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ErrorLabel from 'components/atoms/ErrorLabel'
import { pxToRem } from 'helpers'

const StyledInput = styled.input`
  padding-top: ${space.m};
  padding-bottom: ${space.m};
  padding-right: ${space.m};
  border: 1px solid ${({ isError, theme }) => (isError ? theme.colors.error : theme.colors.border_color)};
  background: transparent;
  border-radius: ${radius.l};
  height: ${({ isSmall, isLarge, height }) => height || (isSmall ? pxToRem(30) : isLarge ? pxToRem(50) : pxToRem(40))};
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  font-size: ${fontSizes.xs};
  :focus {
    outline-style: none;
  }
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::placeholder {
    color: ${({ theme }) => theme.colors.input_placeholder_color};
    font-size: ${fontSizes.xs};
    font-weight: ${fontWeights.normal};
  }
  ${styledSpace};
  ${width};
`

const StyledIcon = styled(Flex)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`

// this is saved MUI input field. Not to be removed, if once we would like to change our component to MUI. Was done for variant="outlined" size="small" v4.8.3

// const StyledTextField = styled(TextField)`
//   .MuiInputBase-root {
//     color: ${({ color }) => color || colors.primaryText};
//     border-radius: ${radius.l};
//     height: ${({ isSmall, isLarge, height }) =>
//   height || (isSmall ? pxToRem(30) : isLarge ? pxToRem(50) : pxToRem(40))};
//     background: ${COLOR_CONSTANTS.WHITE};
//   }
//
//   .MuiInputLabel-root {
//     color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
//     font-size: ${fontSizes.xs};
//     font-weight: ${fontWeights.normal};
//     font-family: ${fontStack};
//   }
//
//   .MuiInputBase-input {
//     color: ${({ color }) => color || colors.primaryText};
//     font-size: ${fontSizes.xs};
//     font-weight: ${fontWeights.normal};
//     font-family: ${fontStack};
//     padding-top: ${space.m};
//     padding-bottom: ${space.m};
//     padding-right: ${space.m};
//     padding-left: ${space.s} !important;
//   }
//
//   .MuiOutlinedInput-notchedOutline,
//   .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
//     border: 1px solid ${({ error }) => (error ? colors.error : COLOR_CONSTANTS.SOLITUDE)};
//   }
//
//   .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
//     border-color: ${colors.primaryText};
//     border-width: 1px;
//   }
//
//   .MuiInputLabel-outlined.MuiInputLabel-marginDense {
//     top: 50%;
//     left: ${space.s};
//     transform: translate(0, -50%);
//   }
//
//   .MuiInputLabel-outlined.MuiInputLabel-shrink {
//     top: 0 !important;
//     left: 0 !important;
//     transform: translate(14px, -${space.s}) scale(1) !important;
//   }
// `

const Input = ({ error, iconComp, label, labelFontSize = 's', secondaryLabel, innerRef, ...props }) => {
  return (
    <Flex flexDirection="column" position="relative">
      {label && (
        <Text
          mb="xs"
          fontSize={labelFontSize}
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
      )}
      {secondaryLabel && (
        <Text mb="s" fontSize="xs" color="secondaryText">
          {secondaryLabel}
        </Text>
      )}

      <Flex flexDirection="column" position="relative">
        {iconComp && (
          <StyledIcon p="s" justifyContent="center" alignItems="center">
            {iconComp}
          </StyledIcon>
        )}
        <StyledInput
          ref={innerRef}
          pl={iconComp ? space.l : space.s}
          isError={!!error}
          autoComplete="nope"
          {...props}
        />
      </Flex>
      {error && <ErrorLabel mt="xxs" textAlign="left" error={error} />}
    </Flex>
  )
}

Input.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconComp: PropTypes.node,
  label: PropTypes.string,
  labelFontSize: PropTypes.string,
}

export default Input
