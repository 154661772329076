import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import ReactSelect, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import { transparentize } from 'polished'
import { space, radius, fontSizes, fontWeights } from 'theme'
import { pxToRem } from 'helpers'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'
import ErrorLabel from 'components/atoms/ErrorLabel'

export const getOptionBackgroundColorFromState = ({ isSelected, isFocused }, mainTheme) => {
  if (isSelected) {
    return mainTheme.colors.primary
  }
  if (isFocused) {
    return transparentize(0.75, mainTheme.colors.primary)
  }
  return mainTheme.colors.background
}

export const getOptionColorFromState = ({ isSelected, isFocused }, mainTheme) => {
  if (isSelected) {
    return mainTheme.colors.white
  }
  if (isFocused) {
    return mainTheme.colors.primaryText
  }
  return mainTheme.colors.primaryText
}

// This is needed in order to turn off the autocomplete, more info here:
// https://github.com/JedWatson/react-select/issues/3500
// Also, Chrome doesn't support turning off autocomplete without a workaround
// https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
// "none" is just a random invalid word
const Input = (props) => <components.Input {...props} autoComplete="nope" />

const DropdownIndicator = (data) => {
  const { isMulti = false, setValue, options } = data

  return (
    <Flex mr="m" alignItems="center">
      {isMulti && (
        <Box
          mr="m"
          onClick={() => {
            setValue(options)
          }}
        >
          <Icon.SelectAll width="16px" height="16px" />
        </Box>
      )}
      <Box>
        <Icon.ArrowDropdown {...data} />
      </Box>
    </Flex>
  )
}

const IndicatorSeparator = () => null

// this is for status filter
const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',
  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginLeft: 8,
    height: 8,
    width: 8,
  },
})

const Select = ({
  height,
  width,
  label,
  error,
  innerRef,
  dropDownType,
  showDropDownArrow,
  multiValueLabelColor,
  showStatusDot,
  stylesControlOverride,
  isSmall,
  theme: mainTheme,
  ...props
}) => {
  const [windowHeight, setWindowHeight] = useState(0)
  const [maxMenuHeight, setMaxMenuHeight] = useState(300)
  const elementRef = useRef(null)

  useEffect(() => {
    if (elementRef && elementRef.current) {
      const parentHeight = elementRef.current.offsetParent ? elementRef.current.offsetParent.clientHeight || 0 : 0
      const elementTopOffset = elementRef.current.offsetTop
      if (parentHeight - elementTopOffset - 80 >= 300) {
        setMaxMenuHeight(300)
      } else {
        setMaxMenuHeight(155)
      }
    }
  }, [windowHeight])

  useEffect(() => {
    const handleWindowResize = () => setWindowHeight(window.innerHeight)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const isError = !!error

  const updatedProps = {
    menuPlacement: 'auto',
    styles: {
      placeholder: (base) => ({
        ...base,
        color: mainTheme.colors.input_placeholder_color,
        fontSize: fontSizes.xs,
        fontWeight: fontWeights.normal,
        marginLeft: 0,
      }),
      container: (base) => ({
        ...base,
        width,
      }),
      control: (base, state) => ({
        ...base,
        height: isSmall ? pxToRem(30) : height,
        fontSize: isSmall ? fontSizes.s : fontSizes.xs,
        borderRadius: '6px',
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: isError
          ? mainTheme.colors.error
          : state.isFocused
          ? mainTheme.colors.border_color
          : mainTheme.colors.border_color,
        '&:hover': {
          borderColor: state.isFocused ? mainTheme.colors.border_color : mainTheme.colors.border_color,
        },
        background: 'transparent',
        ...stylesControlOverride,
      }),
      option: (base, state) => {
        const backgroundColor = getOptionBackgroundColorFromState(state, mainTheme)
        const color = getOptionColorFromState(state, mainTheme)
        return {
          ...base,
          fontSize: fontSizes.xs,
          fontWeight: 'medium',
          backgroundColor,
          color,
        }
      },
      singleValue: (base) => ({
        ...base,
        margin: `0 ${space.xs} 0 0`,
        color: mainTheme.colors.primaryText,
      }),
      multiValue: (base, { data }) => {
        let dotProps = {}

        if (showStatusDot) {
          dotProps = dot(data.color)
        }

        return {
          ...base,
          backgroundColor: transparentize(0.9, multiValueLabelColor || mainTheme.colors.primary),
          ...dotProps,
        }
      },
      multiValueLabel: (base) => ({
        ...base,
        padding: space.xs,
        fontWeight: 'medium',
        borderRadius: `${radius.s} 0 0 ${radius.s}`,
        color: multiValueLabelColor || mainTheme.colors.primary,
        backgroundColor: 'transparent',
      }),
      multiValueRemove: (base) => ({
        ...base,
        cursor: 'pointer',
        borderRadius: `0 ${radius.s} ${radius.s} 0`,
        color: transparentize(0.6, multiValueLabelColor || mainTheme.colors.primary),
      }),
      noOptionsMessage: (base) => ({
        ...base,
        color: mainTheme.colors.input_placeholder_color,
        fontSize: fontSizes.xs,
        fontWeight: fontWeights.normal,
      }),
      loadingMessage: (base) => ({
        ...base,
        color: mainTheme.colors.input_placeholder_color,
        fontSize: fontSizes.xs,
        fontWeight: fontWeights.normal,
      }),
      menu: (base) => ({
        ...base,
        zIndex: 2,
        backgroundColor: mainTheme.colors.background,
      }),
    },
    theme: (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: mainTheme.colors.primary,
        primary50: transparentize(0.25, mainTheme.colors.primary),
      },
    }),
    components: { Input, DropdownIndicator, IndicatorSeparator },
    maxMenuHeight,
    ...props,
  }

  if (!showDropDownArrow) {
    updatedProps.components.DropdownIndicator = () => null
    updatedProps.components.IndicatorSeparator = () => null
  }

  let SelectComponentTemplate = <ReactSelect ref={innerRef} {...updatedProps} />

  if (dropDownType === 'AsyncSelect') {
    SelectComponentTemplate = <AsyncSelect ref={innerRef} {...updatedProps} />
  } else if (dropDownType === 'CreatableSelect') {
    SelectComponentTemplate = <CreatableSelect ref={innerRef} {...updatedProps} />
  }

  let error_message

  if (error) {
    if (typeof error === 'string') {
      error_message = error
    } else {
      error_message = 'Unexpected error'
      console.error('unexpected error format', error)
    }
  }

  return (
    <React.Fragment>
      {label && (
        <Text mb="xs" fontSize="s">
          {label}
        </Text>
      )}
      {SelectComponentTemplate}
      <Box ref={elementRef} />
      {error_message && <ErrorLabel error={error_message} mt="xs" />}
    </React.Fragment>
  )
}

Select.defaultProps = {
  dropDownType: 'ReactSelect',
  showDropDownArrow: true,
  multiValueLabelColor: null,
  showStatusDot: false,
  stylesControlOverride: {},
  isSmall: false,
}

Select.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  dropDownType: PropTypes.string,
  showDropDownArrow: PropTypes.bool,
  multiValueLabelColor: PropTypes.string,
  showStatusDot: PropTypes.bool,
  stylesControlOverride: PropTypes.object,
  isSmall: PropTypes.bool,
  theme: PropTypes.object.isRequired,
}

export default withTheme(Select)
